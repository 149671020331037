import React, { useContext, useEffect, useState, useRef } from 'react';
import AuthContext from '../context/AuthContext';
import "../Styles/Header.css";
import brasaopr from "../imagens/logoNaturgy.png";

function Header() {

  const { setStart, login  } = useContext(AuthContext);

  // console.log(login);

  // const login = () => {
  //   // console.log('login');
  //   setStart(true);

  //   if(load) {
  //     setLoad(false);
  //   }

  //   if(!load) {
  //     setLoad(true);
  //   }

  // }

  return (
    <header className="header">
      <div className="contentheader">

        <div className="divLogo">
          <div className="container-imgLogo">
            <img className="brasaoLogo" src={brasaopr} alt="menu" />
          </div>

          <div className="childre2">
            <span className="xf2">Minha conta</span>
          </div>

          <div className="childre2">
            <span className="xf2">Meus contratos</span>
          </div>

          <div className="childre2">
            <span className="xf2">Quero ser cliente</span>
          </div>

          <div className="childre2">
            <span className="xf2">Naturgy Ajuda</span>
          </div>

          <div className="childre2">
            <button
              className="btEntrar"
              onClick={() => {login()}}
            >
              Entrar
            </button>
          </div>

          <div className="enter">
            <button
              className="btEntrar"
              onClick={() => {login()}}

            >
              Entrar
            </button>
          </div>

        </div>

      </div>
    </header>
  );
}

export default Header;
