import React from 'react';

const Iconmaos = () => {
  return (
    <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="50px"
    height="50px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
    xmlSpace="preserve"
  >
    <path
      fill="#e27000"
      d="M47.6,88.2c-1.3,1.4-2.3,2.8-3.5,4c-4.8,5-13.4,3.8-16.3-2.8c-1.5-3.4-0.8-7.7,2-10.6
        c2.1-2.2,4.3-4.3,6.4-6.6c1.7-1.9,1.3-4.4-0.8-5.7c-1.2-0.8-2.5-0.8-3.6,0.1c-2.5,2.3-5,4.6-7.3,7.1c-1.4,1.5-1.2,3.1,0.1,4.9
        c1.3,1.7,1.2,3.5-0.1,4.6c-1.5,1.2-3.3,1.1-4.6-0.8c-1-1.5-1.6-3.4-2.4-5.1c-0.2-0.5-0.7-1.1-1.2-1.2c-4.8-0.8-8.5-4.8-8.4-9.5
        c0-2.4,0.8-4.7,2.5-6.6c1.4-1.6,3.2-1.9,4.6-0.8c1.6,1.2,1.7,2.7,0.3,4.7c-1.4,1.9-1.3,3.9,0.2,5.2c1.4,1.3,3.3,1.4,4.7,0.1
        c3.9-3.7,7.7-7.5,11.5-11.4c1.5-1.6,1.3-3.6-0.2-5.1c-1.4-1.4-3.6-1.4-5.1,0.1c-1.6,1.5-3.1,3.1-4.6,4.6c-1.3,1.2-3.2,1.4-4.4,0.3
        c-3.7-3.8-7.7-7.3-10.9-11.5C-1,36.5,1.1,20.7,12,13.8c2.3-1.4,4.8-2.5,7.2-3.6c1.2-0.5,2.9,0.5,3.5,1.9c0.6,1.5,0,3.2-1.4,3.9
        c-1.8,0.9-3.8,1.5-5.5,2.6c-7.7,5-10,15.5-4.9,23.1c2.1,3.1,5.1,5.7,7.8,8.4c0.2,0.2,1.2,0.1,1.5-0.1c0.9-0.7,1.7-1.7,2.6-2.4
        c3.1-2.4,6.7-2.9,10.2-1.3c3.5,1.6,5.7,4.5,5.6,8.6c-0.1,1.6-0.5,3.1-0.8,4.7c-0.1,0.4,0,1,0.3,1.2c5.6,4.2,6.5,7,4.2,13.6
        c0,0.1-0.1,0,0.2,0.3c1.4,1.1,2.8,2.2,4.1,3.4c3.3,3.2,6.5,6.5,9.7,9.7c2.2,2.2,5.1,1.6,6.4-1.2c0.7-1.6-0.1-2.7-1.1-3.7
        c-3.9-4-7.9-7.9-11.8-11.9c-1.8-1.8-2-3.5-0.6-4.9c1.3-1.3,3.4-1.1,5,0.6c3.8,3.8,7.6,7.6,11.4,11.4c0.7,0.6,1.5,1.2,2.3,1.5
        c1.4,0.4,3.2-0.5,3.9-1.7c0.7-1.2,0.5-3.3-0.5-4.4c-3.8-3.9-7.7-7.7-11.5-11.5c-0.5-0.5-1.1-1-1.4-1.6c-0.9-1.4-0.5-3.1,0.7-4.1
        c1.2-0.9,3-0.8,4.3,0.4c3.7,3.6,7.3,7.3,11,10.9c0.5,0.5,0.9,1,1.5,1.4c1.7,1.4,3.8,1.4,5.1,0c1.5-1.6,1.5-3.4-0.2-5.2
        c-1.6-1.7-3.1-3.4-4.8-4.8c-2.7-2.3-3.1-4.3-0.1-7.1c4.4-4.1,8.7-8.4,12.6-13c3.4-4,4.3-8.8,3-14.1c-1.6-6.6-5.8-10.6-12.1-12.6
        c-1.5-0.5-3.2-0.6-4.8-0.6c-11.3,0-22.7-0.1-34,0c-1,0-2.2,0.5-2.9,1.2c-3,2.9-6,5.8-8.9,8.8c-1.9,2-1.6,4.4,0.7,5.9
        c1.5,0.9,3,0.6,4.6-1c2.1-2.1,4.2-4.1,6.2-6.3c1.7-1.9,3.9-2,5.9,0c8.2,8.3,16.4,16.5,24.6,24.8c0.8,0.8,1.5,2,1.5,3
        c0,2.4-2.8,3.6-4.8,2.2c-0.4-0.3-0.8-0.6-1.1-1c-7.2-7.2-14.3-14.4-21.5-21.5c-1.6-1.6-1.6-1.5-3.2,0c-2.1,1.9-4,4.1-6.4,5.5
        c-3.3,1.8-6.8,1.4-9.9-1c-3-2.3-4.2-5.4-3.9-9.1c0.2-2.6,1.5-4.6,3.3-6.4c3.4-3.3,6.7-6.8,10.2-10.1c0.8-0.7,2-1.3,3.1-1.3
        c12.6-0.1,25.2-0.3,37.9,0c9.6,0.2,16.3,5.4,20.4,13.9c2.2,4.6,2.4,9.5,1.4,14.4c-1.1,5.2-4,9.4-7.9,13c-2.4,2.2-4.7,4.6-7,7
        c-1.6,1.6-1.6,1.6,0,3.2c2,2,4.1,3.9,5,6.7c1.6,5.1-1.7,11.5-7.7,12.3c-1,0.1-1.4,0.5-1.6,1.5c-0.8,4.5-3.6,7.2-8,8.1
        c-1,0.2-1.4,0.6-1.6,1.7c-0.7,3.8-3.1,6.6-6.7,7.6c-3.6,1-7.1,0.4-9.9-2.5C50.7,91.1,49.3,89.9,47.6,88.2z M38.7,78.6
        c-1.5,1.4-3,2.8-4.4,4.3c-1.5,1.6-1.4,3.8,0,5.2c1.4,1.4,3.5,1.4,5.1-0.1c1.1-1,2-2.1,3.1-3c0.8-0.7,0.8-1.3,0-2
        C41.2,81.7,38.8,78.5,38.7,78.6z"
    ></path>
  </svg>
  );
};

export default Iconmaos;
