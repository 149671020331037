import React from 'react';

const Iconfatura = () => {
    return (
        <svg
            version="1.1"
            id="Camada_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="50px"
            height="50px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 100 100"
            xmlSpace="preserve"
        >
            <g>
                <path
                    fill="#004570"
                    d="M74.5,2c-11,0-22-0.1-33,0c-2.7,0-5.4,0-8.2,0c-2.5,0-5.1-0.2-7.6,0.1c-3.4,0.4-6.5,2.4-8,5.6
              c-0.8,1.8-1,3.7-1,5.6c0,2.8,0,5.6,0,8.3c0,5.4,0,10.9,0,16.3c0,11,0,21.9,0,32.9c0,5.5-0.1,11.1,0,16.6c0,3.4,1.2,6.7,4.1,8.7
              C24.3,98.6,28.9,98,33,98c5.5,0,11,0,16.5,0c5.5,0,11,0,16.5,0c2.4,0,3.8-0.3,5.3-2.2c1.7-2.1,3.3-4.2,5-6.3
              c1.7-2.1,3.4-4.3,5.1-6.4c1.4-1.8,3.1-3.5,3.3-5.8c0.3-2.7,0-5.5,0.1-8.2c0-2.8,0-5.6,0-8.4c0-10.9,0-21.9,0-32.8
              c0-2.8,0-5.6,0-8.4c0-2.6,0.2-5.2,0-7.8c-0.3-3.5-2.2-6.8-5.4-8.4C78,2.5,76.2,2.1,74.5,2z M68.5,88.7c-0.5-0.2-0.5-0.1-0.6-0.6
              c-0.1-0.3,0-0.7,0-1c0-0.7,0-1.4,0-2.1c0-1-0.3-2.8,0.3-3.6c0.6-0.7,1.9-0.5,2.7-0.5c1.2,0,2.4,0,3.7,0
              C72.5,83.5,70.5,86.1,68.5,88.7z M76.1,74.2c-2.4-0.1-4.8-0.1-7.2-0.1c-1.8,0-4.1-0.3-5.8,0.4c-1.2,0.5-1.8,1.7-1.9,3
              c-0.2,2.3-0.1,4.6-0.1,6.9c0,1.1,0,2.2,0,3.3c0,0.9,0.2,2-0.1,2.9c-0.6,1.4-3.3,0.9-4.5,0.9c-4.8,0-9.6,0-14.5,0
              c-4.9,0-9.8,0.1-14.7,0c-1.3,0-2.8-0.3-3.5-1.5c-0.9-1.5-0.6-3.5-0.6-5.2c0-9.8,0-19.6,0-29.4c0-9.7,0-19.4,0-29.2
              c0-2.4,0-4.9,0-7.3c0-2.2-0.2-4.5,0.1-6.6c0.1-1.1,0.5-2.2,1.6-2.7c1.5-0.8,3.4-0.5,5-0.5c9.8,0,19.6,0,29.3,0
              c4.9,0,9.8-0.1,14.8,0c1.2,0,2.6,0.1,3.5,1c1,1.1,0.9,2.8,0.9,4.1c0,9.7,0,19.4,0,29.1c0,4.9,0,9.8,0,14.7c0,2.5,0,4.9,0,7.4
              c0,2.3,0.2,4.7,0,7.1C78.3,73.8,77.5,74.2,76.1,74.2z"
                ></path>
                <path
                    fill="#004570"
                    d="M42.1,23.2c-5,2.1-8.6,6.1-10.3,11.2c-1.9,5.6-1.8,11.9,0.9,17.3c2.6,5.3,7,9.4,12.8,11
              c2.8,0.8,5.9,1.1,8.8,1c1.8-0.1,3.6-0.4,5.4-0.6c0.7-0.1,1.3-0.1,1.7-0.7c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0.2-0.3,0.2-0.3
              c0-0.1-0.6-0.4-0.7-0.5c-1.4-1-3-0.4-4.6-0.3c-1.5,0.1-3,0.2-4.5,0.1c-3-0.1-5.9-0.7-8.6-2c-5.2-2.5-9.2-7.8-10.1-13.5
              c-0.8-5.2,0.5-11.3,4-15.4c3.8-4.5,10.1-6.3,15.8-5.1c5.5,1.2,10.5,4.9,12.3,10.4c1.6,4.8,0.5,10.5-3.2,14c-1.8,1.8-4.2,2.7-6.7,3
              c-2.6,0.3-5.5,0.2-7.8-1.4c-1.9-1.4-3.4-3.6-3.6-6C44.2,43.4,44.8,43,46,43c1.9,0,3.9,0,5.8,0c1.8,0,3.6,0,5.4,0
              c1.3,0,2.7,0.4,2.9-1.2c0.3-2.3-0.1-4.8-1.2-6.8c-2.3-4.1-7.6-5.3-11.9-4c-6.2,1.8-7.4,9-6.5,14.5c0.8,5.4,5.4,9.4,10.6,10.2
              c5,0.8,10.3-1,13.9-4.6c3.7-3.7,4.7-9.1,3.9-14.1c-0.8-4.9-3.2-9.2-7.3-12c-3.8-2.6-8.4-3.7-13-3.4C46.4,21.8,44.2,22.3,42.1,23.2z
               M50.5,33.3c1.4,0.1,2.8,0.4,3.9,1.4c1.4,1.3,1.7,3.3,1.7,5.1c-3.9,0-7.7,0-11.6,0C44.7,36.3,46.6,33.2,50.5,33.3z"
                ></path>
            </g>
        </svg>
    );
};

export default Iconfatura;
