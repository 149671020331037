import React from 'react';

const Iconpx = () => {
    return (
        <svg
            version="1.1"
            id="Camada_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="50px"
            height="50px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 100 100"
            xmlSpace="preserve"
        >
            <g>
                <path
                    fill="#004571"
                    d="M46.33,68.37h14.67v-7.27h7.31v-7.36h-7.22v-7.42h7.35v7.26h7.31v-7.25h7.38v7.38h-7.17v7.33h7.21v14.81h-7.28v7.32h-7.48v-7.24h-14.62v7.22h-7.45v-14.77ZM75.68,75.71v-14.6h-7.13v14.6h7.13Z"
                ></path>
                <path
                    fill="#004571"
                    d="M38.93,16.86v22.03h-22.07v-22.03h22.07ZM31.46,31.48v-7.18h-7.17v7.18h7.17Z"
                ></path>
                <path
                    fill="#004571"
                    d="M83.14,38.93h-22.03v-22.07h22.03v22.07ZM75.7,24.29h-7.14v7.19h7.14v-7.19Z"
                ></path>
                <path
                    fill="#004571"
                    d="M16.83,83.14v-22.03h22.06v22.03h-22.06ZM24.3,75.71h7.15v-7.19h-7.15v7.19Z"
                ></path>
                <path
                    fill="#004571"
                    d="M24.18,2v7.45h-14.67v14.71H2c0-.34,0-.67,0-1,0-4.44,0-8.88,0-13.32,0-4.62,3.22-7.85,7.83-7.85,4.47,0,8.94,0,13.41,0,.28,0,.56,0,.94,0Z"
                ></path>
                <path
                    fill="#004571"
                    d="M75.8,9.51V2c.37,0,.7,0,1.03,0,4.44,0,8.88,0,13.32,0,4.62,0,7.84,3.22,7.85,7.84,0,4.44,0,8.88,0,13.32,0,.31,0,.62,0,1.01h-7.42v-14.66h-14.78Z"
                ></path>
                <path
                    fill="#004571"
                    d="M75.82,98v-7.45h14.67v-14.71h7.51c0,.34,0,.67,0,1,0,4.44,0,8.88,0,13.32,0,4.63-3.22,7.85-7.83,7.85-4.44,0-8.88,0-13.32,0-.31,0-.62,0-1.03,0Z"
                ></path>
                <path
                    fill="#004571"
                    d="M2,75.81h7.44v14.71h14.74v7.48c-.37,0-.71,0-1.04,0-4.41,0-8.81,0-13.22,0-4.74,0-7.92-3.19-7.92-7.95,0-4.38,0-8.75,0-13.13,0-.34,0-.68,0-1.12Z"
                ></path>
                <path
                    fill="#004571"
                    d="M38.98,46.3h14.68v14.72h-7.35v-7.25h-7.32v-7.47Z"
                ></path>
                <path
                    fill="#004571"
                    d="M53.65,38.91h-7.34v-14.7h7.34v14.7Z"
                ></path>
                <path
                    fill="#004571"
                    d="M24.15,53.72h-7.28v-7.4h7.28v7.4Z"
                ></path>
            </g>
        </svg>
    );
};

export default Iconpx;
