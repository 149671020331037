import React from 'react';

const Icon = () => {
  return (
    <svg
      version="1.1"
      id="Camada_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="50px"
      height="50px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve"
    >
      <g>
        <path
          fill="none"
          d="M61.5,63.1c2.7,1.8,5.5,3.6,8.3,5.3c1.3,0.8,2.2,1.6,3.6,0.8c1.3-0.8,2.6-1.6,3.8-2.4c1.3-0.9,2.7-1.7,4-2.6
          c0.4-0.2,0.8-0.5,1.1-0.7c0.2-0.2,0.8-0.4,1-0.6c0.4-0.7-1.1-0.5-1.6-0.5c-3.2,0-6.4,0-9.6,0c-3.8,0-7.6,0-11.4,0
          C60.9,62.6,61.1,62.9,61.5,63.1z"
        ></path>
        <path
          fill="none"
          d="M28.9,34c1.7,0,3.4,0,5,0c1.3,0,2.8,0.2,4-0.1c1.8-0.5,0.2-3.5-0.6-4.4c-1.6-1.7-4.9-2.3-6.9-1
          c-1.1,0.7-2,1.9-2.4,3.1C27.7,32.7,27.5,34,28.9,34z"
        ></path>
        <path
          fill="none"
          d="M61.7,70.9c-2.7-1.9-5.4-3.6-8.3-5.3c0,4.9,0,9.9,0,14.8c0.5-0.1,0.7-0.1,1.1-0.5c0.5-0.4,0.9-0.9,1.4-1.4
          c0.9-0.9,1.8-1.8,2.7-2.8c0.9-0.9,1.7-1.8,2.5-2.7C61.5,72.6,62.7,71.6,61.7,70.9z"
        ></path>
        <path
          fill="none"
          d="M77.1,75.7c-0.8-0.8-1.4-0.5-2.2,0.1c-0.8,0.6-1.8,1.1-2.8,1.1c-1.1,0-1.9-0.6-2.8-1.2
          c-0.9-0.6-1.4-0.2-2.1,0.5c-3.2,3.4-6.6,6.6-9.7,10.2c9.9,0,19.8,0,29.7,0c-1.2-2-3.1-3.5-4.7-5.1C80.6,79.4,78.8,77.5,77.1,75.7z"
        ></path>
        <path
          fill="none"
          d="M91.1,65.4c-3,1.9-6.1,3.8-9.1,5.8c3.2,3.2,6.5,6.5,9.7,9.7c0-2.7,0-5.4,0-8.1c0-1.3,0-2.5,0-3.8
          c0-0.7,0-1.4,0-2.1c0-0.3,0.1-0.7,0-0.9C91.6,65.5,91.6,65.5,91.1,65.4z"
        ></path>
        <path
          fill="#004571"
          d="M88.8,55.6c-9.4,0-18.9,0-28.3,0c-3.5,0-7.4-0.5-10.4,1.7c-2.8,2.1-3.1,5.3-3.1,8.5c0,4.7,0.1,9.3,0,14
          c0,3.6-0.6,7.2,2,10.2c2.7,3.1,6.5,2.8,10.2,2.8c4.7,0,9.4,0,14,0c5.3,0,10.6,0,15.8,0c3.8,0,8.9-1.9,8.9-6.4c0-7.7,0-15.3,0-23
          c0-2.3,0-4.5-2.1-6C93.9,55.8,91.2,55.6,88.8,55.6z M83.2,62.3c0.2,0.3,0.2,0.3,0.1,0.5c-0.1,0.2-0.7,0.4-0.8,0.5
          c-0.5,0.3-1,0.6-1.4,1c-1,0.7-2,1.3-3.1,2c-1,0.7-2,1.3-3.1,2c-0.8,0.5-1.8,1.4-2.8,1.4c-1,0-2.1-1-2.9-1.5c-1-0.7-2.1-1.3-3.1-2
          c-1.9-1.2-4-2.3-5.5-3.9C68.2,62.3,75.7,62.3,83.2,62.3z M54.2,80.2c-0.2,0.2-0.6,0.2-0.8,0.2c0-4.9,0-9.9,0-14.8
          c1.5,0.9,3,1.8,4.5,2.8c0.8,0.5,1.7,1.1,2.5,1.6c0.6,0.4,1.5,0.8,1.6,1.6c0.1,0.8-0.9,1.4-1.4,1.9c-0.7,0.7-1.4,1.5-2.1,2.2
          C57.1,77.3,55.7,78.8,54.2,80.2z M57.4,86.4c2.3-2.7,4.8-5.1,7.3-7.7c0.6-0.6,1.3-1.3,1.9-2c0.5-0.5,1.1-1.5,1.9-1.5
          c0.7,0,1.2,0.8,1.8,1c0.6,0.3,1.4,0.5,2.1,0.5c0.8,0,1.5-0.3,2.1-0.7c0.5-0.3,1-1,1.7-0.9c0.8,0,1.4,1,1.9,1.5
          c0.7,0.7,1.3,1.4,2,2.1c1.3,1.3,2.5,2.6,3.8,3.9c1.2,1.2,2.4,2.3,3.3,3.8C77.2,86.4,67.3,86.4,57.4,86.4z M91.7,80.9
          c-3.2-3.2-6.5-6.5-9.7-9.7c1.9-1.2,3.8-2.4,5.7-3.6c0.9-0.6,1.8-1.2,2.8-1.8c0.4-0.3,1-0.6,1.2-0.1c0.2,0.4,0,1.3,0,1.7
          C91.7,71.9,91.7,76.4,91.7,80.9z"
        ></path>
        <path
          fill="#004571"
          d="M21,86.6c-3.4,0-6.9,0.3-10.3-0.1c-2.3-0.3-2.4-2-2.4-4c0-7.4,0-14.8,0-22.1c0-14.8,0-29.6,0-44.5
          c0-1.5-0.7-5,1.3-5.8c1.1-0.4,2.5-0.2,3.7-0.2c1.9,0,3.7,0,5.6,0c7.4,0,14.7,0,22.1,0c3.7,0,7.4,0,11.1,0c1.5,0,3.1-0.1,4.6,0
          c0.7,0.1,1.5,0.1,2,0.7c0.5,0.6,0.5,1.5,0.6,2.3c0.1,1.7,0,3.4,0,5.1c0,3.7,0,7.4,0,11.1c0,3.6,0,7.3,0,10.9c0,1.7-0.2,3.5,0,5.3
          c0.1,1,0.5,2,1.5,2.6c2,1.1,4.3-0.1,4.6-2.3c0.2-1.7,0.1-3.5,0.1-5.2c0-1.9,0-3.7,0-5.6c0-7.3,0-14.7,0-22c0-2.8-0.7-5.4-2.9-7.2
          c-2-1.7-4.5-2-7.1-2c-7.4,0-14.7,0-22.1,0c-3.7,0-7.4,0-11.2,0c-3.6,0-7.2-0.2-10.8,0c-4.5,0.2-8.2,2.6-9.1,7.2
          C2,12.1,2,13.6,2,15.1c0,1.8,0,3.6,0,5.4c0,3.7,0,7.4,0,11.1c0,14.7,0,29.4,0,44c0,1.9,0,3.7,0,5.6c0,1.6-0.1,3.2,0.2,4.8
          c0.5,2.3,1.8,4.4,3.9,5.6c2.4,1.5,5.2,1.2,7.9,1.2c1.8,0,3.7,0,5.5,0c1.5,0,3.1,0,4.2-1.2c0.8-0.9,0.8-2.2,0.3-3.3
          C23.5,87.2,22.3,86.7,21,86.6z"
        ></path>
        <path
          fill="#004571"
          d="M47,23c-3.6-4-8.5-6.3-13.9-6.2c-4.9,0.1-10,1.9-13.4,5.5c-7.7,8.2-6.3,22.2,2.1,29.3
          c4.8,4,11,4.7,17.1,4.5c0.9,0,2.9,0.3,3.4-0.8c0.3-0.7,0.3-2.1-0.5-2.3c-0.4-0.1-0.9,0.1-1.3,0.1c-0.9,0.1-1.7,0.2-2.6,0.3
          c-1.6,0.1-3.3,0-4.9-0.2c-5.1-0.8-9.9-3.2-12.8-7.6c-2.7-4.2-3.6-9.5-2.3-14.3c1.4-5.1,5.1-9.6,10.2-11.3
          c5.2-1.7,11.8-0.1,15.8,3.6c3.3,3.1,4.9,7.9,4.1,12.4c-0.4,2.2-1.2,4.5-2.8,6.1c-1.8,1.8-4.4,2.9-6.9,3.3c-2.6,0.4-5.4,0.1-7.5-1.5
          c-1.6-1.3-2.9-3.2-3.1-5.3c-0.1-0.9,0-1.8,1.1-1.9c1.5-0.2,3.3,0,4.8,0c1.6,0,3.1,0,4.7,0c1.1,0,3.1,0.4,4-0.4
          c0.7-0.5,0.2-2.5,0.1-3.3c-0.1-1.3-0.4-2.5-1-3.6c-1-1.9-2.8-3.1-4.8-3.7c-3.5-1.1-7.7-0.4-10.2,2.4c-1.5,1.6-2.1,3.7-2.4,5.8
          c-0.3,2.5-0.4,5,0.4,7.4c1.7,5.1,7,7.7,12.1,7.7c4.9-0.1,9.6-2.4,12.1-6.7c2.3-3.9,2.8-8.9,1.5-13.3C49.6,26.7,48.5,24.7,47,23z
           M27.8,32.7c0.3-2.8,2.7-4.8,5.4-4.9c2.7,0,4.8,1.5,5.4,4.1c0.1,0.6,0.3,1.3-0.2,1.7c-0.7,0.5-2.1,0.3-2.9,0.3c-1.7,0-3.3,0-5,0
          C29.4,34,27.6,34.3,27.8,32.7z"
        ></path>
        <path
          fill="#004571"
          d="M42.4,64.5c-8.1,0-16.2,0-24.4,0c-0.4,0-0.9,0-1.3,0c-1.2,0.1-2.1,1.3-1.2,2.3c0.7,0.8,2.5,0.5,3.4,0.5
          c5,0,9.9,0,14.9,0c2.3,0,4.7,0,7,0c0.5,0,1,0,1.5,0C44.2,67.2,44.3,64.5,42.4,64.5z"
        ></path>
        <path
          fill="#004571"
          d="M42.4,70.2c-8.1,0-16.2,0-24.4,0c-0.4,0-0.9,0-1.3,0c-1.2,0.1-2.1,1.3-1.2,2.3c0.7,0.8,2.5,0.5,3.4,0.5
          c5,0,9.9,0,14.9,0c2.3,0,4.7,0,7,0c0.5,0,1,0,1.5,0C44.2,72.9,44.3,70.2,42.4,70.2z"
        ></path>
        <path
          fill="#004571"
          d="M42.4,75.9c-8.1,0-16.2,0-24.4,0c-0.4,0-0.9,0-1.3,0c-1.2,0.1-2.1,1.3-1.2,2.3c0.7,0.8,2.5,0.5,3.4,0.5
          c5,0,9.9,0,14.9,0c2.3,0,4.7,0,7,0c0.5,0,1,0,1.5,0C44.2,78.6,44.3,75.9,42.4,75.9z"
        ></path>
      </g>
    </svg>
  );
};

export default Icon;
